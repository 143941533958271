import * as ActionTypes from './ActionTypes';

export const actionCreators = {
  setArtist: artist => ({ type: ActionTypes.SET_ARTIST, artist }),
  setMp3s: mp3s => ({ type: ActionTypes.SET_MP3S, mp3s }),
  setGigs: gigs => ({ type: ActionTypes.SET_GIGS, gigs }),
  setVideos: videos => ({ type: ActionTypes.SET_VIDEOS, videos }),
  setSonglist: songlist => ({ type: ActionTypes.SET_SONGLIST, songlist }),
  setYouTubeVideos: videos => ({ type: ActionTypes.SET_YOUTUBEVIDEOS, videos }),
  setInstagram: images => ({ type: ActionTypes.SET_INSTAGRAM, images }),
  setGallery: gallery => ({ type: ActionTypes.SET_GALLERY, gallery })
};
