import React from 'react';
import { Image } from 'react-bootstrap';
import Navigation from './Navigation';

import './Footer.scss';

const Footer =  (props) => (
  <section className='Footer pt-5 pb-3 px-5'>
    <div className='logo'>
      <Image src='/svg/porkpie.svg' />
    </div>
    <div className='mt-3'>
      {/* <p>
        Live Scottish Ska band PorkPie will be performing live at a venue near you and one step beyond..
      </p> */}
    </div>
    <Navigation />
    <div className='copyright'>
      <p>&copy; {`${new Date().getFullYear()} PORKPIE BAND`}</p>
    </div>
  </section>
);

export default Footer;