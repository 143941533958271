import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { isLength, isEmail, isAfter } from 'validator';
import HeaderImage from '../components/HeaderImage';
import moment from 'moment';
import RestUtilities from '../services/RestUtilities';
import {config} from '../const/config';
import './Booking.scss';

class Band extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      number: '',
      location: '',
      date: '',
      message: '',
      nameIsValid: false,
      emailIsValid: false,
      numberIsValid: false,
      locationIsValid: false,
      dateIsValid: false,
      messageIsValid: false,
      nameIsValidated: false,
      emailIsValidated: false,
      numberIsValidated: false,
      locationIsValidated: false,
      dateIsValidated: false,
      messageIsValidated: false,
      disableSubmit: false,
      result: false,
      showMessage: false
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  handleNameChange(e) {
    const name = e.currentTarget.value;
    const nameIsValid = isLength(name, { min: 1, max: 50 });
    this.setState({ name, nameIsValid });
  }

  handleEmailChange(e) {
    const email = e.currentTarget.value;
    const emailIsValid = isEmail(email);
    this.setState({ email, emailIsValid });
  }

  handleNumberChange(e) {
    const number = e.currentTarget.value;
    const numberIsValid = isLength(number, { min: 7, max: 20 });
    this.setState({ number, numberIsValid });
  }

  handleLocationChange(e) {
    const location = e.currentTarget.value;
    const locationIsValid = isLength(location, { min: 1, max: 255 });
    this.setState({ location, locationIsValid });
  }

  handleMessageChange(e) {
    const message = e.currentTarget.value;
    const messageIsValid = isLength(message, { min: 1, max: 1000 });
    this.setState({ message, messageIsValid });
  }

  handleDateChange(e) {
    const date = e.currentTarget.value;
    const dateIsValid = isAfter(date);
    this.setState({ date, dateIsValid });
  }

  handleSubmitClick() {
    if (this.isFormValid()) {
      this.setState({ disableSubmit: true });
    }
    this.submit();
  }

  isFormValid() {
    const { nameIsValid, emailIsValid, numberIsValid, locationIsValid, dateIsValid, messageIsValid } = this.state;
    return nameIsValid && emailIsValid && numberIsValid && locationIsValid && dateIsValid && messageIsValid;
  }

  async submit() {
    this.setState({ nameIsValidated: true, emailIsValidated: true, numberIsValidated: true, locationIsValidated: true, messageIsValidated: true });
    const formIsValid = this.isFormValid();
    if (formIsValid) {
      const { name, email, number, location, date, message } = this.state;
      const data = {
        name,
        email,
        phone: number,
        location,
        date: moment(date).format(),
        message
      };
      const url = `${config.apiUrl}/email`;
      const response = await RestUtilities.post(url, data);
      if (response.ok) {
        this.setState({ result: true, showMessage: true });
      } else {
        this.setState({ result: false, showMessage: true, disableSubmit: false });
      }
    }
  }

  render() {
    return (
      <div className="Booking">
        <HeaderImage heading="BOOKING" imgSrc="booking.jpg" darken={0.2} checkTop checkBottom />
        <Container className="content py-4 px-5" fluid>
          <h3>CONTACT</h3>
          <Row>
            <Col>
              <p>
                To make an enquiry about the band, simply fill out our contact form. Alternatively, drop us a line at{' '}
                <a href="mailto:info@porkpieband.co.uk">info@porkpieband.co.uk</a>, phone us on or send a message on Facebook messenger.
              </p>
              <p>
                You can also reach the band via <a href="https://www.musicforscotland.co.uk">Music For Scotland</a>.
              </p>
            </Col>
            <Col>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>NAME</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    required
                    maxLength={50}
                    onChange={this.handleNameChange}
                    onBlur={() => this.setState({ nameIsValidated: true })}
                    isInvalid={this.state.nameIsValidated && !this.state.nameIsValid}
                    isValid={this.state.nameIsValidated && this.state.nameIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>EMAIL</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="my@email.com"
                    required
                    onChange={this.handleEmailChange}
                    onBlur={() => this.setState({ emailIsValidated: true })}
                    isInvalid={this.state.emailIsValidated && !this.state.emailIsValid}
                    isValid={this.state.emailIsValidated && this.state.emailIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a email.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formTelephone">
                  <Form.Label>PHONE NUMBER</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="0123 456 789"
                    required
                    maxLength={50}
                    onChange={this.handleNumberChange}
                    onBlur={() => this.setState({ numberIsValidated: true })}
                    isInvalid={this.state.numberIsValidated && !this.state.numberIsValid}
                    isValid={this.state.numberIsValidated && this.state.numberIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a contact number.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEvent">
                  <Form.Label>EVENT LOCATION</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Venue, City"
                    required
                    maxLength={255}
                    onChange={this.handleLocationChange}
                    onBlur={() => this.setState({ locationIsValidated: true })}
                    isInvalid={this.state.locationIsValidated && !this.state.locationIsValid}
                    isValid={this.state.locationIsValidated && this.state.locationIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please enter your venue or location</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEvent">
                  <Form.Label>EVENT DATE</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    min={moment().format('YYYY-MM-DD')}
                    onChange={this.handleDateChange}
                    onBlur={() => this.setState({ dateIsValidated: true })}
                    isInvalid={this.state.dateIsValidated && !this.state.dateIsValid}
                    isValid={this.state.dateIsValidated && this.state.dateIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please choose a date in the future</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEvent">
                  <Form.Label>YOUR MESSAGE</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="Hi there, this is my message..."
                    required
                    maxLength={1000}
                    onChange={this.handleMessageChange}
                    onBlur={() => this.setState({ messageIsValidated: true })}
                    isInvalid={this.state.messageIsValidated && !this.state.messageIsValid}
                    isValid={this.state.messageIsValidated && this.state.messageIsValid}
                  />
                  <Form.Control.Feedback type="invalid">Please enter your message</Form.Control.Feedback>
                </Form.Group>
                <Button size="lg" block className="my-4 p-3" onClick={this.handleSubmitClick} disabled={this.state.disableSubmit}>
                  SEND YOUR MESSAGE
                </Button>
                {this.state.showMessage && (
                  <React.Fragment>
                    {this.state.result && <Alert variant="success">Your message was sent successfully! We'll be in touch, thanks.</Alert>}
                    {this.state.result || <Alert variant="danger">Oops,Something went wrong, please try to submit again</Alert>}
                  </React.Fragment>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Band;
