import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Table, Col, Row } from 'react-bootstrap';
import HeaderImage from '../components/HeaderImage';

import './Shows.scss';

class Shows extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  renderRow(gig) {
    const date = new moment(gig.date);
    return (
      <tr key={gig.id}>
        <td className="text-center">
          <div className="text-uppercase">{date.format('MMM')}</div>
          <div className="date-day">{date.format('DD')}</div>
        </td>
        <td className="align-middle">{gig.location}</td>
        <td className="align-middle">{gig.venue}</td>
        <td className="align-middle">
          {gig.link ? (
            <a href={gig.link} target="_blank" rel="noopener noreferrer">
              Click here to buy tickets
            </a>
          ) : (
            <span>{gig.eventDetail}</span>
          )}
        </td>
      </tr>
    );
  }
  renderMobileRow(gig) {
    const date = new moment(gig.date);
    return (
      <Row key={gig.id} className="gig-row">
        <Col xs={2} className="text-center">
          <div className="text-uppercase">{date.format('MMM')}</div>
          <div className="date-day">{date.format('DD')}</div>
        </Col>
        <Col xs={10} className="align-middle">
          <div className="align-middle">
            <span className="font-weight-bold">Location: </span>
            {gig.location}
          </div>
          <div className="align-middle">
            <span className="font-weight-bold">Venue: </span>
            {gig.venue}
          </div>
          <div className="align-middle">
            <span className="font-weight-bold">Details: </span>
            {gig.link ? (
              <a href={gig.link} target="_blank" rel="noopener noreferrer">
                Click here to buy tickets
              </a>
            ) : (
              <span>{gig.eventDetail}</span>
            )}
          </div>
        </Col>
      </Row>
    );
  }
  render() {
    const showMobile = this.state.width < 500;
    return (
      <div className="Shows">
        <HeaderImage heading="SHOWS" imgSrc="music.jpg" darken={0.5} checkTop checkBottom />
        <Container className="content py-4 px-5" fluid>
          <h3>UPCOMING SHOWS</h3>
          {showMobile || <hr />}
          {showMobile || (
            <Table striped hover responsive size="sm">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>LOCATION</th>
                  <th>VENUE</th>
                  <th>TICKETS</th>
                </tr>
              </thead>
              <tbody>{this.props.gigs.map(g => this.renderRow(g))}</tbody>
            </Table>
          )}
          {showMobile && this.props.gigs.map(g => this.renderMobileRow(g))}
        </Container>
      </div>
    );
  }
}
export default connect(state => ({ gigs: state.gigs }))(Shows);
