export const config =  {
  blobUrl: 'https://mfsresources.blob.core.windows.net',
  apiUrl: 'https://mfscoreadmin.azurewebsites.net/api/PorkPie',
  instagramOptions: {
    clientId: 'e11c818390184dde9494efafc7c171f9',
    accessToken: '9285101391.1677ed0.7f2727a3889d43e28a9ef866280e191c',
    get: 'user',
    locationId: null,
    resolution: 'standard_resolution',
    sortBy: 'most-recent',
    tagName: null,
    userId: 9285101391,
    feedSize: 4,
  }
};
