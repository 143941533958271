import React, { Component } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import ReactSVG from 'react-svg';
import classnames from 'classnames';
import './Navigation.scss';

class Navigation extends Component {
  render() {
    return (
      <Navbar variant='dark' expand='lg' className={classnames('Navigation', this.props.isTop ? 'top' : 'bottom')}>
        {this.props.isTop && (
          <IndexLinkContainer to={'/'}>
            <Navbar.Brand className='xs-invisible'>
              <ReactSVG
                src='/svg/porkpie.svg'
                wrapper='span'
                className={classnames('social-link', 'd-none', 'd-sm-block')}
              />
            </Navbar.Brand>
          </IndexLinkContainer>
        )}
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='navbar'>
          <Nav className='mr-auto'>
            <IndexLinkContainer to={'/'}>
              <NavItem>
                <div className='hover-wrapper'>
                  <span data-hover='Home'>Home</span>
                </div>
              </NavItem>
            </IndexLinkContainer>
            <LinkContainer to={'/band'}>
              <NavItem>
                <div className='hover-wrapper'>
                  <span data-hover='The Band'>The Band</span>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to={'/shows'}>
              <NavItem>
                <div className='hover-wrapper'>
                  <span data-hover='Shows'>Shows</span>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to={'/video'}>
              <NavItem>
                <div className='hover-wrapper'>
                  <span data-hover='Media/Video'>Media/Video</span>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to={'/booking'}>
              <NavItem>
                <div className='hover-wrapper'>
                  <span data-hover='Booking'>Booking</span>
                </div>
              </NavItem>
            </LinkContainer>
          </Nav>
          <Nav className='d-none d-xl-flex'>
            <Nav.Link href='https://www.facebook.com/porkpieband/'>
              <ReactSVG src='/svg/fb-icon.svg' wrapper='span' className='social-link' />
            </Nav.Link>
            <Nav.Link href='https://www.instagram.com/porkpie335/'>
              <ReactSVG src='/svg/instagram-icon.svg' wrapper='span' className='social-link' />
            </Nav.Link>
            {/* <Nav.Link href="#deets">
              <ReactSVG src="/svg/twitter-icon.svg" wrapper="span" className="social-link" />
            </Nav.Link>
            <Nav.Link href="#deets">
              <ReactSVG src="/svg/youtube-icon.svg" wrapper="span" className="social-link" />
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Navigation;
