import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Actions';
import Navigation from './Navigation';
import Footer from './Footer';
import RestUtilities from '../services/RestUtilities';
import {config} from '../const/config';

class Layout extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const url = `${config.apiUrl}/get`;
    RestUtilities.get(url).then(async (response) => {
      if (response.ok) {
        const artist = response.content;
        const videos = artist.videos.filter(x => x.isLive && !x.isAccoustic).sort((a, b) => a.orderIndex - b.orderIndex);
        const gallery = artist.artistMedia
          .filter((x) => x.mediaTypeId === 1 && x.isLive)
          .sort((a, b) => a.orderIndex - b.orderIndex);
        const gigs = artist.gigs
          .filter((x) => x.eventTypeId === 8 && new Date(x.date) > new Date())
          .sort((a, b) => new Date(a.date) - new Date(b.date));
        delete artist.videos;
        delete artist.youTubeVideos;
        delete artist.artistRepertoires;
        delete artist.artistMedia;
        this.props.setArtist(artist);
        this.props.setVideos(videos);
        this.props.setGigs(gigs);
        this.props.setGallery(gallery);
      } else {
        console.log('did not update data');
      }
    });
  }

  render() {
    return (
      <div className='Layout'>
        <Navigation isTop showBrand />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default connect(
  (state) => ({ artist: state.artist }),
  (dispatch) => bindActionCreators(actionCreators, dispatch)
)(Layout);
