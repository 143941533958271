import React from 'react';
import classnames from 'classnames';
import ReactSVG from 'react-svg';

import './HeaderImage.scss';

const HeaderImage =  props => (
  <React.Fragment>
    <section
      className={classnames('Header-Image', props.small && 'small', props.large && 'large', props.onClick && 'link')}
      style={{
        background: props.darken
          ? `linear-gradient( rgba(0, 0, 0, ${props.darken}), rgba(0, 0, 0, ${props.darken}) ), url('/images/${props.imgSrc}')`
          : `url('/images/${props.imgSrc}')`
      }}
      onClick={props.onClick}
    >
      {props.heading && <h2>{props.heading}</h2>}
      {props.svg && <ReactSVG src={`/svg/${props.svg}`} wrapper="div" className="logo" />}
      {props.checkTop && <div className="check top" />}
      {props.checkBottom && <div className="check bottom" />}
    </section>
  </React.Fragment>
);

export default HeaderImage;