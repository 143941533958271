import React from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './views/Home';
import Band from './views/Band';
import Shows from './views/Shows';
import Video from './views/Video';
import Booking from './views/Booking';
import './scss/app.scss';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/band" component={Band} />
            <Route exact path="/shows" component={Shows} />
            <Route exact path="/video" component={Video} />
            <Route exact path="/booking" component={Booking} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
