import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Actions';
import { Container, Row, Col, Image, Button, Modal } from 'react-bootstrap';
import HeaderImage from '../components/HeaderImage';
import ReactSVG from 'react-svg';
import {config} from '../const/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Band.scss';

class Band extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      artistMediaIndex: null
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleShowImage(artistMediaIndex) {
    this.setState({ artistMediaIndex });
    this.toggleModal();
  }

  handleImageBack() {
    const { artistMediaIndex } = this.state;
    if (!this.isFirst()) {
      this.setState({ artistMediaIndex: artistMediaIndex - 1 });
    }
  }

  handleImageForward() {
    const { artistMediaIndex } = this.state;
    if (!this.isLast()) {
      this.setState({ artistMediaIndex: artistMediaIndex + 1 });
    }
  }

  isFirst() {
    return this.state.artistMediaIndex === 0;
  }

  isLast() {
    return this.state.artistMediaIndex === this.props.gallery.length - 1;
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    const currentImage = this.props.gallery.length ? this.props.gallery[this.state.artistMediaIndex] : null;
    return (
      <div className="Band">
        <HeaderImage heading="THE BAND" imgSrc="band.jpg" darken={0.5} checkTop checkBottom />
        <Container className="content py-4">
          <h3>WHO ARE PORK PIE</h3>
          <p>
            If you were into music in the late 70s and early 80s, the chances are you were into 2-Tone, the iconic Coventry based label that was home to Ska
            revivalists like The Beat, The Selecter, Madness and The Specials. Porkpie are the real deal, from the classic ‘big’ ska band mould, with a full
            brass section, keys, guitars and porkpie hats to match we play with an intensity and energy that will have you skanking into the night! Check out
            our gallery from previous events , set lists and where you can come and see us below !
          </p>
          <p className="font-weight-bold">
            <a href="https://www.facebook.com/porkpieband/">
              <ReactSVG src="/svg/fb-icon.svg" wrapper="span" className="social-link" />
            </a>
            <a href="https://www.instagram.com/porkpie335/">
              <ReactSVG src="/svg/instagram-icon.svg" wrapper="span" className="social-link" />
            </a>
          </p>
        </Container>
        <Container className="content-gallery py-3" fluid>
          <h3 className="text-center">GALLERY</h3>
          <Row>
            {this.props.gallery.map((x, i) => (
              <Col lg={3} md={4} xs={6} key={x.artistMediaId} className="image-wrapper">
                <div className="image-container d-flex justify-content-center">
                  <Image src={`${config.blobUrl}/images/${x.upload.fileName}`} fluid className="align-self-center" onClick={() => this.handleShowImage(i)} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className="content-upcoming py-3">
          <h3 className="text-center">UPCOMING SHOWS</h3>
          <p>Want to see PorkPie live? Visit out Upcoming Shows page for full information on our events. We can't wait to see you there!</p>
          <Button
            size="lg"
            className="my-5 py-3 px-5"
            onClick={() => {
              this.props.history.push('/shows');
              window.scrollTo(0, 0);
            }}
          >
            SEE UPCOMING SHOWS
          </Button>
        </Container>
        <Modal show={this.state.showModal} onHide={this.toggleModal} className="lightbox" size="xl" centered>
          <Modal.Body>
            <div className="icon arr-close d-flex justify-content-center" onClick={this.toggleModal}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {this.isFirst() || (
              <div className="icon arr-left d-flex justify-content-center" onClick={() => this.handleImageBack()}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
            )}
            {this.isLast() || (
              <div className="icon arr-right d-flex justify-content-center" onClick={() => this.handleImageForward()}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            )}
            <div className="gallery-image-wrapper">
              {currentImage && <Image src={`${config.blobUrl}/images/${currentImage.upload.fileName}`} fluid className="align-self-center" />}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    artist: state.artist,
    gallery: state.gallery
  }),
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Band);
