import * as ActionTypes from './ActionTypes';

const initialState = {
  artist: null,
  gallery: [],
  songlist: [],
  mp3s: [],
  gigs: [],
  uploads: [],
  videos: [],
  youTubeVideos: [],
  instagram: []
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case ActionTypes.SET_ARTIST:
      return { ...state, artist: action.artist };
    case ActionTypes.SET_UPLOADS:
      return { ...state, uploads: action.uploads };
    case ActionTypes.SET_SONGLIST:
      return { ...state, songlist: action.songlist };
    case ActionTypes.SET_MP3S:
      return { ...state, mp3s: action.mp3s };
    case ActionTypes.SET_GIGS:
      return { ...state, gigs: action.gigs };
    case ActionTypes.SET_VIDEOS:
      return { ...state, videos: action.videos };
    case ActionTypes.SET_YOUTUBEVIDEOS:
      return { ...state, youTubeVideos: action.videos };
    case ActionTypes.SET_INSTAGRAM:
      return { ...state, instagram: action.images };
    case ActionTypes.SET_GALLERY:
      return { ...state, gallery: action.gallery };
    default:
      return { ...state };
  }
};
