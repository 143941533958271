import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {config} from '../const/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './Video.scss';

class Band extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVideo: props.videos.filter(video => !video.isAccoustic)[0],
      playing: false,
      showStopControl: false
    };
    this.togglePlay = this.togglePlay.bind(this);
  }

  onClickThumbnail = video => {
    this.setState({ activeVideo: video });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  togglePlay() {
    this.setState({ playing: !this.state.playing });
  }

  renderVideos() {
    return this.props.videos
      .map(video => (
        <Col key={video.videoId} lg={{ span: 4 }} md={{ span: 6 }}>
          <div
            className="thumbnail"
            style={{
              backgroundImage: `url(${config.blobUrl}/images/${video.imageUpload.fileName})`
            }}
            onClick={() => this.onClickThumbnail(video)}
          />
        </Col>
      ));
  }


  render() {
    const { activeVideo, playing } = this.state;
    const hasVideos = !!this.props.videos?.length;
    const hasMoreThanOneVideo = hasVideos && this.props.videos.length > 1
    return (
      <div className="Video">
        <div
          className="player-wrapper d-flex justify-content-center align-items-center"
          onMouseEnter={() => this.setState({ showStopControl: true })}
          onMouseLeave={() => this.setState({ showStopControl: false })}
        >
          {!this.state.playing && !!activeVideo && (
            <React.Fragment>
              <div className="control d-flex justify-content-center align-items-center play" onClick={this.togglePlay}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
              <div className="poster" style={{ backgroundImage: `url(${config.blobUrl}/images/${activeVideo.imageUpload.fileName})` }} />
            </React.Fragment>
          )}
          {this.state.playing && this.state.showStopControl && (
            <div className="control d-flex justify-content-center align-items-center pause" onClick={this.togglePlay}>
              <FontAwesomeIcon icon={faPause} />
            </div>
          )}

          {!!activeVideo && 
          <ReactPlayer
            ref={this.player}
            className="video-player"
            width="100%"
            height="100%"
            url={`${config.blobUrl}/videos/${activeVideo.videoUpload.fileName}`}
            playing={playing}
            controls={this.state.playing}
            // light={`${config.blobUrl}/images/${activeVideo.imageUpload.fileName}`}
            loop={false}
            playbackRate={1.0}
            volume={0.8}
            onPlay={() => this.setState({ playing: true })}
            onPause={() => this.setState({ playing: false })}
          />
  }
        </div>
        {hasMoreThanOneVideo && 
        <Container fluid className="content px-5">
          <h3>MORE VIDEOS</h3>
          <Row noGutters>{this.renderVideos()}</Row>
          <Row className="band-info-container">
            <p>
              Porkpie boasts an extensive repertoire that aims to please everyone. Pur eclectic Soul, Ska , Swing and Pop classics will include music from
              Madness, James Brown, Elvis and Bob Marley to Paulo Nultini, The Blues Brothers and Wilson Picket and the band can bring the evening to a finale
              with some well-loved classic pop hits.
              <br />
              <Link className="content-link" to="/music">
                Check out our repertoire.
              </Link>
            </p>
            <Link className="button-link" to="/booking">
              Book Now
            </Link>
          </Row>
        </Container>}
        {!hasVideos && <Container fluid className="content px-5 pb-5"><div>Please check back later, we will add videos soon.</div></Container>}
      </div>
    );
  }
}

export default connect(state => ({ videos: state.videos }))(Band);
