import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store/ConfigureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';

const initialState = window.initialReduxState;
const rootElement = document.getElementById('root');

const init = async () => {
  const { store, persistor } = await configureStore(initialState);
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    rootElement
  );
};

serviceWorker.unregister();
init();
