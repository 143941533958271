import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderImage from '../components/HeaderImage';
import './Home.scss';

class Home extends Component {
  navigate(path) {
    this.props.history.push(path);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Home">
        <HeaderImage imgSrc="home.jpg" large svg="porkpie.svg" checkTop />
        <HeaderImage heading="THE BAND" imgSrc="band.jpg" small onClick={() => this.navigate('/band')} />
        <HeaderImage heading="SHOWS" imgSrc="shows.jpg" small onClick={() => this.navigate('/shows')} />
        <HeaderImage heading="MEDIA/VIDEO" imgSrc="media.jpg" small onClick={() => this.navigate('/video')} />
        <HeaderImage heading="BOOKING" imgSrc="booking.jpg" small onClick={() => this.navigate('/booking')} />
      </div>
    );
  }
}

export default connect(state => ({ artist: state.artist }))(Home);
